<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12" v-if="loading">
        <div style="text-align: center" class="mt-5 mb-5">
          <pulse-loader color="#505d69" :loading="true"></pulse-loader>
        </div>
      </div>
      <div class="col-lg-12" v-else>
        <div class="card">
          <div class="card-body">
            <b-card-title>
              <h4 class="card-title">Project Info - {{ projectNumber }}</h4>
            </b-card-title>
            <div class="row">
              <div class="col-6">
                <FormulateForm name="createManufacture" v-model="values" :schema="searchScheme" @submit="submitHandler">
                  <div class="row">
                    <div class="col-3">

                      <FormulateInput type="submit">
                        <span v-if="performAction">
                          <b-spinner class="vueformulate-loader"></b-spinner>
                          <span v-if="this.$route.query.id"> Updating... </span>
                          <span v-else> Adding... </span>
                        </span>
                        <span v-else>
                          <span v-if="this.$route.query.id"> Update Project </span>
                          <span v-else> Add Project </span>
                        </span>
                      </FormulateInput>
                    </div>
                    <div class="col-3" >
                      <button class="btn btn-primary" type="button" @click="goToMoreInfo">
                        Add More Info
                      </button>
                    </div>
                  </div>

                </FormulateForm>
              </div>
              <div v-if="this.$route.query.id" class="col-6">
                <UploadImage :project="values" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loading && this.$route.query.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-card-title>
              <h4 class="card-title">Advance Info</h4>
            </b-card-title>
            <div class="row">
              <div class="col-3">
                <FormulateForm name="createManufacture" v-model="values" :schema="advScheme" @submit="updateadvInfo">
                  <FormulateInput type="submit">
                    <span v-if="updatingAdvInfo">
                      <b-spinner class="vueformulate-loader"></b-spinner>
                      <span> Updating... </span>
                    </span>
                    <span v-else>Update Project</span>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loading && this.$route.query.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-card-title>
              <h4 class="card-title">Settings</h4>
            </b-card-title>
            <div class="row">
              <div class="col-12">
                <b-button variant="danger" @click="hideProject" class="mr-4">Delete</b-button>
                <b-button variant="warning" @click="promoteToTop">Promote to Top 5</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PromoteToTop :selectedProject="selectedProject" />
  </Layout>
</template> 
<script>
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PromoteToTop from "@/components/projects/promote-top";
import PageHeader from "@/components/page-header";
import { projects } from "@/config/api/projects";
import UploadImage from "@/components/projects/add-image"
/**
 * Add Project Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    PromoteToTop,
    UploadImage
  },
  created() {

    this.performAction = true;
    const api = projects.status.get;
    this.generateAPI(api)
      .then((res) => {
        this.statusData = res.data.models.map((item) => {
          return { label: item.name, value: item._id }
        });
      })
      .catch(() => {

      }).finally(() => {
        this.performAction = false;
      });
  },
  data() {
    return {
      statusData: [],
      loading: false,
      title: "Projects",
      items: [
        {
          text: "Create",
        },
        {
          text: "Projects",
          active: true,
        },
      ],
      selectedProject: null,
      advScheme: [
        {
          index: 0,
          type: "hidden",
          name: "project_id",
          label: "",
          "validation-messages": { required: "Project Number is required" },

          validation: "required",
          disabled: true,
        },
        {
          index: 1,
          type: "checkbox",
          name: "is_verified",
          label: "Verified",
        },
        {
          index: 2,
          type: "checkbox",
          name: "status",
          label: "Active",
        },
      ],
      projectNumber: "",
      performAction: false,
      updatingImage: false,
      updatingAdvInfo: false,
      values: {},
      searchResult: undefined,
    };
  },
  computed: {
    searchScheme() {
      return this.$route.query.id
        ? [
          {
            index: 0,
            type: "text",
            name: "project_id",
            label: "Project Number",
            "validation-messages": { required: "Project Number is required" },

            validation: "required",
            disabled: true,
          },
          {
            index: 1,
            type: "text",
            name: "name_ar",
            label: "Project Arabic Name",
            "validation-messages": { required: "Project Name is required" },
            validation: "required",
          },
          {
            index: 2,
            type: "text",
            name: "name_en",
            label: "Project English Name",
            "validation-messages": { required: "Project Name is required" },
            validation: "required",
          },
          {
            index: 3,
            type: "number",
            name: "project_area",
            label: "Project Area in square meter",
            min: "0",
          },
          {
            index: 4,
            type: "number",
            name: "project_cost",
            label: "Project Cost",
            min: "0",
          },
        ]
        : [
          {
            index: 0,
            type: "text",
            name: "project_id",
            label: "Project Number",
            "validation-messages": { required: "Project Number is required" },

            validation: "required",
          },
        ];
    },
  },
  watch: {},
  async beforeMount() {
    this.loading = true;
    if (this.$route.query.id) {
      this.projectNumber = this.$route.query.id;
      await this.searchProject();
    }
    this.loading = false;
  },
  methods: {

    createNew() { 
      this.$router.push({
        path: "project-info",
        query: { id: this.searchResult },
      });
    },

    goToMoreInfo() { 
      this.$router.push({
        path: "project-info",
        query: { id: this.$route.query.id },
      });
    },
    submitHandler() {
      this.performAction = true;
      const api = this.$route.query.id
        ? projects.model.update
        : projects.model.create;
      api.data = this.values;
      this.generateAPI(api)
        .then(() => {
          this.performAction = false;
          if (!this.$route.query.id) {
            this.$router.push({
              path: "/edit-projects",
              query: {
                id: this.values.project_id,
                type: "new",
              },
            });
          }
          this.$bvToast.toast("Project updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.performAction = false;
          this.$bvToast.toast("Project is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.searchProject();
        });
    },
    updateadvInfo( ) { 
      this.updatingAdvInfo = true;
      const api = projects.model.update;
      api.data = this.values;
      this.generateAPI(api)
        .then(() => {
          console.log("test");
          this.$bvToast.toast("Project updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.updatingAdvInfo = false;
        })
        .catch(() => {
          this.$bvToast.toast("Project is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          this.updatingAdvInfo = false;
        })
        .finally(() => {
          this.searchProject();
        });
    },

    async searchProject() {
      this.loading = true;
      if (this.$route.query.id && this.$route.query.type === "new") {
        const api = projects.model.create;
        api.data = { project_id: this.$route.query.id };
        try {
          let res = await this.generateAPI(api);
          this.searchResult = res.data.project;
          this.values = res.data.project;
        } catch (error) {
          console.error(error.response);
        }
      } else {
        const api = projects.model.search;
        api.id = this.projectNumber;
        try {
          let res = await this.generateAPI(api);
          this.searchResult = res.data.project;
          this.values = res.data.project;
        } catch (error) {
          console.error(error.response);
        }
      }

      this.loading = false;
    },
    hideProject() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure, you want to delete this slider?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            console.log("test");
            this.loading = true;
            const api = projects.model.hide;
            api.id = this.searchResult._id;
            this.generateAPI(api)
              .then(async () => {
                await this.$bvToast.toast("Project deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Projects" });
                }, 3000);
                this.loading = false;
              })
              .catch((err) => {
                this.$bvToast.toast("Project is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Projects" });
                }, 3000);
                console.error(err);
              });
          }
        });
    },
    promoteToTop() {
      this.selectedProject = this.searchResult;
      this.$bvModal.show("action-promote");
    },
  },
};
</script>