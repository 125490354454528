<template>
  <b-modal
    id="action-promote"
    @hidden="$emit('resetModal')"
    title="Promote to Top 5"
    hide-footer
  >
    <FormulateForm
      name="PromoteTopAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Promoting... </span>
        </span>
        <span v-else> Promote </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { projects } from "@/config/api/projects";
export default {
  props: ["selectedProject"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "number",
          name: "rank",
          label: "Rank",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      const api = projects.model.changerating;

      api.data = { rank: data.rank, project_id: this.selectedProject._id };

      this.performAction = true;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Project promoted successfully", {
            title: "Project promotion",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Project is not promoted, ${err.response.data.message}`,
            {
              title: "Project promotion",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },

};
</script>

<style>
</style>